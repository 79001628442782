<form class="ui form" *ngIf="ready" (ngSubmit)="create()">
    <div class="fields">
        <div class="seven wide field">
            <label>{{'group_name' | translate}}</label>
            <sm-select class="fluid search" [options]="{'fullTextSearch': true}"
                [(model)]="newGroupPermission.group.name" id="PermissionGroupName">
                <option *ngFor="let g of groupList" [ngValue]="g">{{g.name}}</option>
            </sm-select>
        </div>
        <div class="five wide field">
            <label>{{'group_permission' | translate}}</label>
            <sm-select class="fluid search" [options]="{'fullTextSearch': true}"
                [(model)]="newGroupPermission.permission" id="PermissionGroupLevel">
                <option *ngFor="let p of permissionList" [value]="p.value">{{p.name | translate}}</option>
            </sm-select>
        </div>
        <div class="two wide center field">
            <button *ngIf="buttonType === 'submit'" type="submit" class="ui green button" [class.loading]="loading"
                [class.disabled]="loading || !newGroupPermission.group.name || newGroupPermission.group.name === ''"><i
                    class="save icon"></i>{{ 'btn_save' | translate }}</button>
            <button *ngIf="buttonType === 'button'" type="submit" class="ui icon blue button" [class.loading]="loading"
                [class.disabled]="loading || !newGroupPermission.group.name || newGroupPermission.group.name === ''"><i
                    class="plus icon"></i></button>
        </div>
    </div>
</form>
