<div class="content" *ngIf="display">
    <div class="ui form">
        <h3 class="inline">{{ 'workflow_node_hook_form_title' | translate }}</h3>
        <div class="right floated mb15">
            <i class="book icon"></i><a href="#" [routerLink]="['/docs', 'docs', 'workflow', 'hooks']" target="_blank"
                rel="noopener noreferrer">{{'common_documentation' | translate}}</a>
        </div>
        <ng-container *ngIf="!loadingModels">
            <sui-select class="selection" placeholder="{{'workflow_node_hook_select' | translate}}"
                [(ngModel)]="selectedOutgoingHookModel" (ngModelChange)="updateOutgoingHook()"
                [options]="outgoingHookModels" [isSearchable]="true" [isDisabled]="mode === 'update' || mode === 'ro'"
                labelField="name" #select>
                <sui-select-option *ngFor="let option of select.filteredOptions" [value]="option">{{option.name}}
                </sui-select-option>
            </sui-select>
        </ng-container>
        <ng-container *ngIf="loadingModels">
            <sui-select class="selection" placeholder="{{'common_loading' | translate}}">
                <sui-select-option value="">{{'common_loading' | translate}}</sui-select-option>
            </sui-select>
        </ng-container>

        <ng-container *ngIf="selectedOutgoingHookModel && displayConfig && outgoingHook && outgoingHook.outgoing_hook">
            <h3>{{ 'workflow_node_hook_form_config' | translate }}</h3>
            <ng-container *ngIf="outgoingHook.outgoing_hook.config && selectedOutgoingHookModel.name !== 'Workflow'">
                <div class="inline fields" *ngFor="let k of outgoingHook.outgoing_hook.config | keys">
                    <div class="four wide field"><label>{{k}}</label></div>
                    <div class="twelve wide field">
                        <input type="text" [(ngModel)]="outgoingHook.outgoing_hook.config[k].value"
                            (ngModelChange)="pushChange()"
                            [readonly]="!outgoingHook.outgoing_hook.config[k].configurable || mode === 'ro'"
                            *ngIf="k !== 'payload' && (outgoingHook.outgoing_hook.config[k].type === 'string' || !outgoingHook.outgoing_hook.config[k].type)" />
                        <input type="password" [(ngModel)]="outgoingHook.outgoing_hook.config[k].value"
                            (ngModelChange)="pushChange()"
                            [readonly]="!outgoingHook.outgoing_hook.config[k].configurable || mode === 'ro'"
                            *ngIf="k !== 'payload' && outgoingHook.outgoing_hook.config[k].type === 'password'" />
                        <ng-container *ngIf="k === 'payload' && outgoingHook.outgoing_hook.config[k].type === 'string'">
                            <codemirror [class.invalid]="invalidJSON"
                                [(ngModel)]="outgoingHook.outgoing_hook.config[k].value" [config]="codeMirrorConfig"
                                (change)="changeCodeMirror($event)" #textareaCodeMirror>
                            </codemirror>
                        </ng-container>
                    </div>
                </div>
            </ng-container>


            <ng-container *ngIf="selectedOutgoingHookModel.name === 'Workflow'">
                <div class="inline fields">
                    <div class="four wide field"><label>project</label></div>
                    <div class="twelve wide field">
                        <input type="text" [(ngModel)]="outgoingHook.outgoing_hook.config['target_project'].value"
                            [disabled]="true" />
                    </div>
                </div>
                <div class="inline fields" *ngIf="availableWorkflows">
                    <div class="four wide field"><label>workflow</label></div>
                    <div class="twelve wide field">
                        <sui-select class="selection" placeholder="{{'workflow_name' | translate}}"
                            [(ngModel)]="outgoingHook.outgoing_hook.config['target_workflow'].value"
                            (ngModelChange)="updateWorkflowData(true)" [options]="availableWorkflows"
                            [isSearchable]="true" [isDisabled]="mode === 'ro'" labelField="name" #selectWorkflow
                            valueField="name">
                            <sui-select-option *ngFor="let option of selectWorkflow.filteredOptions" [value]="option">
                                {{option.name}}</sui-select-option>
                        </sui-select>
                    </div>
                </div>
                <div class="inline fields" *ngIf="availableHooks">
                    <div class="four wide field"><label>hook</label></div>
                    <div class="twelve wide field">
                        <sui-select class="selection" placeholder="Hook"
                            [(ngModel)]="outgoingHook.outgoing_hook.config['target_hook'].value"
                            (ngModelChange)="updateWorkflowOutgoingHook()" [options]="availableHooks"
                            [isSearchable]="true" [isDisabled]="mode === 'ro'" labelField="uuid" valueField="uuid"
                            #selectHook>
                            <sui-select-option *ngFor="let option of selectHook.filteredOptions" [value]="option">
                                {{option.name}} </sui-select-option>
                        </sui-select>
                    </div>
                </div>
                <div class="inline fields"
                    *ngIf="availableHooks && outgoingHook.outgoing_hook.config['target_hook'].value">
                    <div class="four wide field"><label>payload</label></div>
                    <div class="twelve wide field">
                        <codemirror [class.invalid]="invalidJSON"
                            [(ngModel)]="outgoingHook.outgoing_hook.config['payload'].value" [config]="codeMirrorConfig"
                            (change)="changeCodeMirror($event)" #textareaCodeMirror>
                        </codemirror>
                    </div>
                </div>
            </ng-container>
            <div class="ui info message" *ngIf="!outgoingHook.outgoing_hook.config">
                {{ 'workflow_node_hook_no_configuration' | translate }}</div>
        </ng-container>
        <ng-container *ngIf="mode === 'update' && workflow.permissions.writable">
            <button class="ui right floated green button" *ngIf="workflow.permissions.writable" [disabled]="loading"
                [class.loading]="loading" (click)="updateWorkflow()">
                {{ 'btn_save' | translate }}
            </button>
        </ng-container>
    </div>
</div>
