<ng-container>
    <div class="content">
        <div class="ui form">
            <h3 class="inline">{{ 'workflow_node_hook_form_title' | translate }}</h3>
            <div class="right floated mb15">
                <i class="book icon"></i><a href="#" [routerLink]="['/docs', 'docs', 'workflow', 'hooks']" target="_blank" rel="noopener noreferrer">{{'common_documentation' | translate}}</a>
            </div>
            <ng-container *ngIf="isRun">
                <input class="ui fluid input" [value]="hook?.hook_model_name" [readOnly]="true">
            </ng-container>
            <ng-container *ngIf="!isRun">
                <ng-container *ngIf="!loadingModels">
                    <sui-select class="selection" placeholder="{{'workflow_node_hook_select' | translate}}"
                                [(ngModel)]="selectedHookModel"
                                (ngModelChange)="updateHookModel()"
                                [options]="hooksModel"
                                [isSearchable]="true"
                                [isDisabled]="hook?.uuid || mode === 'ro'"
                                labelField="name" #select>
                        <sui-select-option *ngFor="let option of select.filteredOptions" [value]="option">{{option.name}}
                        </sui-select-option>
                    </sui-select>
                </ng-container>
                <ng-container *ngIf="loadingModels">
                    <sui-select class="selection" placeholder="{{'common_loading' | translate}}">
                        <sui-select-option value="">{{'common_loading' | translate}}</sui-select-option>
                    </sui-select>
                </ng-container>
            </ng-container>

            <div class="inline fields uuid" *ngIf="hook?.uuid">
                <div class="four wide field"><label>UUID</label></div>
                <div class="twelve wide field">
                    <input type="text" value="{{hook.uuid}}" disabled>
                </div>
            </div>
            <ng-container *ngIf="hook?.model && displayConfig">
                <ng-container *ngIf="hook.config && hook.model.name !== 'Workflow'">
                    <h3>{{ 'workflow_node_hook_form_config' | translate }}</h3>
                    <div class="inline fields" *ngFor="let k of hook.config | keys">
                        <div class="four wide field"><label>{{k}}</label></div>
                        <div class="twelve wide field">
                            <!-- STRING -->
                            <ng-container *ngIf="k !== 'payload' && (hook.config[k].type === 'string' || !hook.config[k].type)">
                                <input type="text" [(ngModel)]="hook.config[k].value" [readonly]="!hook.config[k].configurable || mode === 'ro'"/>
                            </ng-container>
                            <!-- PASSWORD -->
                            <ng-container  *ngIf="k !== 'payload' && hook.config[k].type === 'password'">
                                <input type="password" [(ngModel)]="hook.config[k].value"
                                       [readonly]="!hook.config[k].configurable || mode === 'ro'"/>
                            </ng-container>
                            <!-- MULTICHOICE -->
                            <ng-container *ngIf="k !== 'payload' && hook.config[k].type === 'multiple' && hook.config[k].multiple_choice_list">
                                <div class="ui form">
                                    <sui-multi-select class="selection"
                                                [(ngModel)]="tempMultipleConfig"
                                                [options]="hook.config[k].multiple_choice_list"
                                                (ngModelChange)="updateConfigMultipleChoice(k)"
                                                [isSearchable]="true"
                                                [isDisabled]="mode === 'ro'"
                                                #select>
                                        <sui-select-option *ngFor="let option of select.filteredOptions"
                                                           [value]="option">
                                        </sui-select-option>
                                    </sui-multi-select>
                                </div>
                            </ng-container>

                            <!-- PAYLOAD -->
                            <ng-container *ngIf="k === 'payload' && hook.config[k].type === 'string'">
                                <codemirror
                                    [class.invalid]="invalidJSON"
                                    [(ngModel)]="hook.config[k].value"
                                    [config]="codeMirrorConfig"
                                    (change)="changeCodeMirror($event)"
                                    #textareaCodeMirror>
                                </codemirror>
                            </ng-container>
                            <ng-container *ngIf="hook.config[k].type === 'integration'">
                                <sui-select class="selection" placeholder="{{'integration_name' | translate}}"
                                            [(ngModel)]="selectedIntegration"
                                            (ngModelChange)="updateIntegration()"
                                            [options]="availableIntegrations"
                                            [isSearchable]="true"
                                            labelField="name" #select>
                                    <sui-select-option *ngFor="let option of select.filteredOptions" [value]="option">{{option.name}}
                                    </sui-select-option>
                                </sui-select>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div class="ui info message" *ngIf="!hook.config">{{ 'workflow_node_hook_no_configuration' | translate }}</div>
            </ng-container>

            <button class="ui right floated green button" type="button" *ngIf="mode === 'update'" (click)="updateHook()"
                    [disabled]="loading" [class.loading]="loading">
                <span *ngIf="workflow.from_repository && workflow.from_repository.length > 0">
                    {{ 'btn_apply' | translate }}
                </span>
                <span *ngIf="!(workflow.from_repository && workflow.from_repository.length > 0)">
                    {{ 'btn_save' | translate }}
                </span>

            </button>
        </div>
    </div>
</ng-container>
