<div class="ui inverted menu navbar">
    <a href="#" [routerLink]="['/']"><img src="assets/images/cds.png" class="item"
            title="{{'navbar_home' | translate}}" /></a>
    <ng-container *ngIf="currentAuthSummary">
        <a class="item link" href="#" [routerLink]="['project', 'list', 'all']">
            {{ 'navbar_projects' | translate }}
        </a>
        <div class="ui pointing dropdown link item" suiDropdown>
            <div class="text">{{'navbar_favorites' | translate}}</div>
            <i class="dropdown icon"></i>
            <div class="menu" suiDropdownMenu>
                <ng-container *ngIf="loading">
                    <div class="item">{{'common_loading' | translate}}</div>
                </ng-container>
                <ng-container *ngFor="let prj of listFavs">
                    <ng-container [ngSwitch]="prj.type">
                        <ng-container *ngSwitchCase="'workflow'">
                            <a class="item" sm-item href="#"
                                [routerLink]="['project', prj.key, 'workflow', prj.workflow_name]">
                                <div class="details">
                                    <div class="title bold">
                                        {{prj.workflow_name}}
                                    </div>
                                    <div class="info mt">
                                        {{'navbar_workflow_in' | translate}} {{prj.key}}
                                    </div>
                                </div>
                            </a>
                        </ng-container>
                        <ng-container *ngSwitchCase="'application'">
                            <a class="item" sm-item href="#"
                                [routerLink]="['project', prj.key, 'application', prj.application_name]">
                                <div class="details">
                                    <div class="title bold">
                                        {{prj.application_name}}}
                                    </div>
                                    <div class="info mt">
                                        {{'navbar_application_in' | translate}} {{prj.key}}
                                    </div>
                                </div>
                            </a>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <a class="item" sm-item href="#" [routerLink]="['project', prj.key]">
                                <div class="details">
                                    <div class="title bold">
                                        {{prj.name}}
                                    </div>
                                    <div class="info mt">
                                        {{'common_project' | translate}}
                                    </div>
                                </div>
                            </a>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <a class="item" sm-item href="#" [routerLink]="['favorite']">
                    {{'common_favorites_all' | translate}}
                </a>
            </div>
        </div>
    </ng-container>
    <a class="item link" href="#" [routerLink]="['/project']" *ngIf="currentAuthSummary">
        {{ 'navbar_project_create' | translate }}
    </a>
    <div class="right menu details">
        <div class="ring" *ngIf="currentAuthSummary">
            <div *ngIf="currentAuthSummary.isMaintainer() && currentAuthSummary.user.ring !== 'ADMIN'"
                class="ui orange horizontal label">Maintainer</div>
            <div *ngIf="currentAuthSummary.user.ring === 'ADMIN' && !currentAuthSummary.isAdmin()"
                class="ui label orange">
                Maintainer
                <a class="detail" (click)="mfaLogin()" title="Elevate to admin"><i class="key icon"></i></a>
            </div>
            <div *ngIf="currentAuthSummary.isAdmin()" class="ui red horizontal label">Admin</div>
        </div>

        <div class="ui search" suiPopup popupPlacement="bottom" [popupTemplate]="popupSearchTemplate"
            popupTrigger="outsideClick" #popup="suiPopup">
            <div class="ui icon input">
                <input class="prompt" type="text" placeholder="{{'navbar_search' | translate}}"
                    [(ngModel)]="searchValue" (blur)="search()" (click)="search()" (keyup)="search()">
                <i class="search icon"></i>
            </div>
        </div>

        <ng-template let-popup #popupSearchTemplate>
            <div class="content searchpopup">
                <div class="ui right floated" suiPopup popupPlacement="left"
                    [popupText]="'navbar_search_help_details' | translate"><i class="help icon"></i>
                    {{ 'navbar_search_help' | translate }}
                </div>

                <div *ngIf="containsResult && isSearch">
                    <div class="ui left"><i class="search icon"></i> {{'navbar_results' | translate}}</div>
                </div>
                <div *ngIf="containsResult && !isSearch">
                    <div class="ui left"><i class="history icon"></i>{{'navbar_seen_recently' | translate}}</div>
                </div>
                <div *ngIf="!containsResult">
                    <div class="ui center"><i class="search icon"></i>{{'navbar_no_result' | translate}}</div>
                </div>
                <div class="ui internally celled grid" *ngIf="containsResult">
                    <div class="four wide column">
                        <div class="ui secondary pointing menu">
                            <div class="ui relaxed divided list">
                                <div class="item">
                                    <div class="content">
                                        {{ 'common_projects' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui secondary pointing menu" *ngIf="!searchProjects || searchProjects.length <= 0">
                            <div class="ui relaxed divided list">
                                <div class="item">
                                    <div class="content">
                                        {{ 'navbar_no_project' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui secondary pointing menu" *ngFor="let el of searchProjects">
                            <div class="ui relaxed divided list">
                                <div class="item">
                                    <i class="pointing star icon favorite" *ngIf="el.favorite"></i>
                                    <div class="content">
                                        <a class="header" (click)="$event.stopPropagation()"
                                            [routerLink]="['/project', el.projectKey]"
                                            title="{{ 'common_project_title' | translate }}{{el.title}}">
                                            {{el.title}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="six wide column">
                        <div class="ui secondary pointing menu">
                            <div class="ui relaxed divided list">
                                <div class="item">
                                    <div class="content">
                                        {{ 'common_workflows' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui secondary pointing menu" *ngIf="!searchWorkflows || searchWorkflows.length <= 0">
                            <div class="ui relaxed divided list">
                                <div class="item">
                                    <div class="content">
                                        {{ 'navbar_no_workflow' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui secondary pointing menu" *ngFor="let el of searchWorkflows">
                            <div class="ui relaxed divided list">
                                <div class="item">
                                    <i class="pointing star icon favorite" *ngIf="el.favorite"></i>
                                    <div class="content">
                                        <a class="header" (click)="$event.stopPropagation()"
                                            [routerLink]="['/project', el.projectKey, 'workflow', el.title]"
                                            title="{{ 'common_workflow_title' | translate }}{{el.title}}">
                                            {{el.title}}
                                        </a>
                                        <div class="description">{{'navbar_in_project' | translate}}
                                            <a (click)="$event.stopPropagation()"
                                                [routerLink]="['/project', el.projectKey]"
                                                title="{{ 'common_project_title' | translate }}{{el.title}}">
                                                {{el.projectKey}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="six wide column">
                        <div class="ui secondary pointing menu">
                            <div class="ui relaxed divided list">
                                <div class="item">
                                    <div class="content">
                                        {{ 'common_applications' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui secondary pointing menu"
                            *ngIf="!searchApplications || searchApplications.length <= 0">
                            <div class="ui relaxed divided list">
                                <div class="item">
                                    <div class="content">
                                        {{ 'navbar_no_application' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui secondary pointing menu" *ngFor="let el of searchApplications">
                            <div class="ui relaxed divided list">
                                <div class="item">
                                    <div class="content">
                                        <a class="header" (click)="$event.stopPropagation()"
                                            [routerLink]="['/project', el.projectKey, 'application', el.title]"
                                            title="{{ 'common_application_title' | translate }}{{el.title}}">
                                            {{el.title}}
                                        </a>
                                        <div class="description">{{'navbar_in_project' | translate}}
                                            <a (click)="$event.stopPropagation()"
                                                [routerLink]="['/project', el.projectKey]"
                                                title="{{ 'common_project_title' | translate }}{{el.title}}">
                                                {{el.projectKey}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <button class="ui icon button link item notification" suiPopup popupPlacement="bottom right"
            [popupTemplate]="popupTemplateBroadcasts" popupTrigger="outsideClick" #popup="suiPopup">
            <div>
                <i class="bell icon" [ngClass]="{'animated bounceIn': broadcasts && broadcasts.length > 0}"></i>
                <div class="ui floating red circular label animated bounceIn"
                    *ngIf="broadcasts && broadcasts.length > 0">{{broadcasts.length}}</div>
            </div>
        </button>
        <ng-template let-popup #popupTemplateBroadcasts>
            <div class="content notifpopup">
                <div class="ui secondary pointing menu">
                    <a class="item" [class.active]="recentView" (click)="recentView = true">
                        {{'broadcast_recent' | translate}}
                    </a>
                    <a class="item" [class.active]="!recentView" (click)="recentView = false">
                        {{'broadcast_seen' | translate}}
                    </a>
                </div>
                <div *ngIf="recentView" class="notification mt5">
                    <div class="ui relaxed divided list"
                        *ngIf="recentBroadcastsToDisplay && recentBroadcastsToDisplay.length > 0">
                        <div class="item" *ngFor="let broadcast of recentBroadcastsToDisplay">
                            <div class="notif">
                                <span class="right floated pointing">
                                    <i class="check icon" suiPopup [popupText]="'navbar_mark_read' | translate"
                                        [popupInverted]="true" popupPlacement="bottom center"
                                        (click)="markAsRead($event, broadcast.id)"></i>
                                </span>
                                <a class="title bold" (click)="popup.close()" [routerLink]="['broadcast', broadcast.id]"
                                    [class.blue]="broadcast.level === 'info'"
                                    [class.orange]="broadcast.level === 'warning'">
                                    {{broadcast.title}}
                                </a>
                                <a class="info mt" [routerLink]="['broadcast', broadcast.id]" (click)="popup.close()">
                                    <span *ngIf="broadcast.project_key">
                                        {{'common_project' | translate}} {{broadcast.project_key}}
                                    </span>
                                    <span *ngIf="!broadcast.project_key">
                                        {{'navbar_general' | translate}}
                                    </span>
                                    <span class="right floated">
                                        {{broadcast.updated | amTimeAgo}}
                                    </span>
                                </a>
                            </div>
                        </div>
                        <a class="item" *ngIf="broadcasts.length > 4" [routerLink]="['broadcast']"
                            (click)="popup.close()">
                            {{'navbar_see_all' | translate}}
                        </a>
                    </div>
                    <div *ngIf="!recentBroadcastsToDisplay || recentBroadcastsToDisplay.length === 0">
                        {{'broadcast_none' | translate}}
                    </div>
                </div>
                <div *ngIf="!recentView">
                    <div class="ui relaxed divided list"
                        *ngIf="previousBroadcastsToDisplay && previousBroadcastsToDisplay.length > 0">
                        <a class="item" *ngFor="let broadcast of previousBroadcastsToDisplay"
                            [routerLink]="['broadcast', broadcast.id]" (click)="popup.close()">
                            <div class="notif">
                                <div class="title bold" [class.blue]="broadcast.level === 'info'"
                                    [class.orange]="broadcast.level === 'warning'">
                                    {{broadcast.title}}
                                </div>
                                <div class="info mt">
                                    <span *ngIf="broadcast.project_key">
                                        {{'common_project' | translate}} {{broadcast.project_key}}
                                    </span>
                                    <span *ngIf="!broadcast.project_key">
                                        {{'navbar_general' | translate}}
                                    </span>
                                    <span class="right floated">
                                        {{broadcast.updated | amTimeAgo}}
                                    </span>
                                </div>
                            </div>
                        </a>
                        <a class="item" [routerLink]="['broadcast']" (click)="popup.close()">
                            {{'navbar_see_all' | translate}}
                        </a>
                    </div>
                </div>
            </div>
        </ng-template>

        <button *ngIf="help?.content?.length > 0" class="ui icon button link item" suiPopup
            popupPlacement="bottom right" [popupTemplate]="popupTemplateHelp" popupTrigger="outsideClick"
            #popup="suiPopup">
            <div>
                <i class="phone icon"></i>
            </div>
        </button>
        <ng-template let-popup #popupTemplateHelp>
            <div class="content helppopup">
                <markdown [data]="help.content"></markdown>
            </div>
        </ng-template>

        <sm-dropdown icon="settings" *ngIf="currentAuthSummary">
            <a sm-item href="#" [routerLink]="['settings', 'user', currentAuthSummary.user.username]">{{
                'navbar_profile' | translate }}</a>
            <a sm-item href="#" [routerLink]="['settings', 'cdsctl']">{{ 'navbar_cdsctl' | translate }}</a>
            <a sm-item href="#" [routerLink]="['settings', 'action']">{{ 'navbar_actions' | translate }}</a>
            <a sm-item href="#" [routerLink]="['settings', 'user']">{{ 'navbar_users' | translate }}</a>
            <a sm-item href="#" [routerLink]="['settings', 'group']">{{ 'navbar_groups' | translate }}</a>
            <a sm-item href="#" [routerLink]="['settings', 'worker-model']">{{ 'navbar_worker_models' | translate }}</a>
            <a sm-item href="#" [routerLink]="['settings', 'workflow-template']">{{ 'navbar_workflow_templates' |
                translate }}</a>
            <div class="divider"></div>
            <a sm-item href="#" [routerLink]="['settings', 'queue']">{{ 'admin_queue_title' | translate }}</a>
            <div class="divider"></div>
            <a sm-item href="#" [routerLink]="['settings', 'downloads']">{{ 'navbar_downloads' | translate }}</a>
            <a sm-item href="#" [routerLink]="['docs', '']" target="_blank" rel="noopener noreferrer">{{
                'navbar_documentation' | translate }}</a>
            <div class="divider"></div>
            <sui-checkbox class="toggle theme-switch" [ngModel]="darkActive" (ngModelChange)="changeTheme()">
                {{'navbar_dark_mode' | translate}}</sui-checkbox>
            <div class="divider"></div>
            <a sm-item (click)="clickLogout()">{{ 'navbar_disconnect' | translate }}</a>
        </sm-dropdown>

        <sm-dropdown icon="student" *ngIf="currentAuthSummary && currentAuthSummary.isMaintainer()">
            <a sm-item href="#" [routerLink]="['admin', 'broadcast']">{{ 'navbar_admin_broadcast' | translate }}</a>
            <a sm-item href="#" [routerLink]="['admin', 'worker-model-pattern']">{{ 'worker_model_pattern_title' |
                translate }}</a>
            <a sm-item href="#" [routerLink]="['admin', 'hooks-tasks']">{{ 'hook_tasks_summary' | translate }}</a>
            <a sm-item href="#" [routerLink]="['admin', 'services']">{{ 'monitoring' | translate }}</a>
        </sm-dropdown>
    </div>
</div>
